import * as Yup from '../../../../../_snowpack/pkg/yup.js';
import * as Sentry from '../../../../../_snowpack/pkg/@sentry/browser.js';
import { ATOM_PLAN, CRYSTAL_PLAN, PARTICLE_PLAN } from './constants.js';
import { CustomerAddressType } from './interfaces.js';
import config from '../../../../app-config.js';
import { ERROR_CANCELLING_SUBSCRIPTION, ERROR_CREATING_NEW_SUBSCRIPTION, ERROR_FETCHING_PLAN } from '../plans.js';
export const customerInitialValues = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  tax: '',
  address: {
    street: '',
    postalCode: '',
    state: '',
    city: '',
    country: '',
    type: CustomerAddressType.billing
  }
};
export const paymentInitialValues = {
  cardholderName: '',
  email: ''
};
export const SubscriptionFormInitialValues = {
  customer: { ...customerInitialValues
  },
  payment: { ...paymentInitialValues
  },
  toggle: false
};
export const AtomFormValidation = Yup.object().shape({
  customer: Yup.object().shape({
    firstName: Yup.string().ensure().required('First name required'),
    lastName: Yup.string().ensure().required('Last name required'),
    email: Yup.string().email('Invalid email').required('Email required'),
    address: Yup.object().shape({
      country: Yup.string().ensure().required('Country is required')
    })
  }),
  payment: Yup.object().shape({
    cardholderName: Yup.string().ensure().required('Cardholder name is required'),
    email: Yup.string().email('Invalid email').required('Email required')
  }),
  toggle: Yup.boolean().oneOf([true], 'Accept payment terms')
});
export const buildStripeCustomer = (customerTenantIdentifier, customer) => {
  const {
    firstName,
    lastName,
    email,
    address,
    tax,
    company
  } = { ...customer
  };
  return {
    name: `${firstName} ${lastName}`,
    email,
    address: {
      city: address.city,
      line1: address.street,
      country: address.country,
      postal_code: address.postalCode,
      state: address.state
    },
    metadata: {
      tax,
      company,
      customerTenantIdentifier
    }
  };
};
export const createStripeCustomerAndSetupIntent = async customer => {
  const query = `
  mutation CREATE_SETUP_INTENT_QUERY($customer: StripeCustomerInput!){
     paymentProviders {
      stripe {
        createCustomerWithSetUpIntent(customer: $customer)
      }
    }
  }`;

  try {
    var _data$paymentProvider, _data$paymentProvider2;

    const setupIntent = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          customer
        }
      })
    });
    const {
      errors,
      data
    } = await setupIntent.json();
    if (errors) return new Error(`Sorry, we are unable to setup your payment information.`);else return (_data$paymentProvider = data.paymentProviders) === null || _data$paymentProvider === void 0 ? void 0 : (_data$paymentProvider2 = _data$paymentProvider.stripe) === null || _data$paymentProvider2 === void 0 ? void 0 : _data$paymentProvider2.createCustomerWithSetUpIntent;
  } catch (error) {
    throw new Error(`Unfortunately, we cannot setup your payment information at the moment.`);
  }
};
export const createCustomerInCrystallize = async (customerValues, customerTenantIdentifier, stripeCustomerId, stripePaymentMethodId) => {
  const {
    firstName,
    lastName,
    email,
    address,
    tax,
    company
  } = customerValues;
  const customer = {
    firstName,
    lastName,
    email,
    identifier: customerTenantIdentifier,
    taxNumber: tax,
    companyName: company,
    addresses: [{ ...address
    }],
    externalReferences: [{
      key: 'stripeCustomerId',
      value: stripeCustomerId
    }],
    meta: stripePaymentMethodId ? [{
      key: 'stripePaymentMethodId',
      value: stripePaymentMethodId
    }] : []
  };
  const query = `
      mutation CREATE_CRYSTALLIZE_CUSTOMER($customer: CreateCustomerInput!) {
        customers {
          create(customer: $customer) 
        }
      }
    `;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          customer
        }
      })
    });
    const {
      data
    } = await response.json();
    if (data.customers.create.errors) return new Error('Sorry, we could not set you up your profile in our system');else return data.customers.create;
  } catch (err) {
    Sentry.captureException(` Error creating a new customer creation: ${err}`);
    throw new Error('Oh dear. We could not set you up in our system due to a glitch');
  }
};
export const createSubscriptionContractInCrystallize = async (customerIdentifier, planName) => {
  const subscriptionContract = {
    itemPath: planName === 'atom' ? '/atom-plan' : '/particle-plan',
    customerIdentifier
  };
  const query = `
        mutation CREATE_SUBSCRIPTION_CONTRACT($subscriptionContract: CreateSubscriptionContractInput!) {
          subscriptions {
            create(input: $subscriptionContract) 
          }
        }
      `;

  try {
    var _data$subscriptions$c;

    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          subscriptionContract
        }
      })
    });
    const {
      data
    } = await response.json();
    if ((_data$subscriptions$c = data.subscriptions.create) !== null && _data$subscriptions$c !== void 0 && _data$subscriptions$c.errors) return new Error(ERROR_CREATING_NEW_SUBSCRIPTION);else return data.subscriptions.create;
  } catch (err) {
    Sentry.captureException(` Error creating a new SubscriptionContract: ${err}`);
    throw new Error(`Unfortunately, we cannot activate your subscription at the moment. Please try again in few minutes`);
  }
};
export const getSubscriptionContracts = async customerIdentifier => {
  if (!customerIdentifier) throw new Error('customerIdentifier is needed to get subscriptions');
  const query = `
  query GET_ALL_SUBSCRIPTION_CONTRACTS($customerIdentifier: String!){
     subscriptions {
        getAll(customerIdentifier: $customerIdentifier)
    }
  }`;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          customerIdentifier: customerIdentifier
        }
      })
    });
    const {
      data
    } = await response.json();
    if (data.subscriptions.getAll.errors) return new Error(ERROR_FETCHING_PLAN);else return data.subscriptions.getAll;
  } catch (err) {
    throw new Error(`Sorry, there is a glitch. We couldn't get your plan information`);
  }
};
export const getActiveSubscriptionContractId = subscriptionContracts => {
  var _activeSub$node;

  // @ts-ignore
  const activeSub = subscriptionContracts === null || subscriptionContracts === void 0 ? void 0 : subscriptionContracts.find(sub => {
    var _sub$node, _sub$node$status, _sub$node2, _sub$node2$status;

    return ((_sub$node = sub.node) === null || _sub$node === void 0 ? void 0 : (_sub$node$status = _sub$node.status) === null || _sub$node$status === void 0 ? void 0 : _sub$node$status.activeUntil) && ((_sub$node2 = sub.node) === null || _sub$node2 === void 0 ? void 0 : (_sub$node2$status = _sub$node2.status) === null || _sub$node2$status === void 0 ? void 0 : _sub$node2$status.renewAt);
  }); // @ts-ignore

  return (activeSub === null || activeSub === void 0 ? void 0 : (_activeSub$node = activeSub.node) === null || _activeSub$node === void 0 ? void 0 : _activeSub$node.id) || '';
};
export const getActiveSubscriptionPlanName = subscriptionContracts => {
  var _activeSub$node2, _activeSub$node2$item;

  // @ts-ignore
  const activeSub = subscriptionContracts === null || subscriptionContracts === void 0 ? void 0 : subscriptionContracts.find(sub => {
    var _sub$node3, _sub$node3$status, _sub$node4, _sub$node4$status;

    return ((_sub$node3 = sub.node) === null || _sub$node3 === void 0 ? void 0 : (_sub$node3$status = _sub$node3.status) === null || _sub$node3$status === void 0 ? void 0 : _sub$node3$status.activeUntil) && ((_sub$node4 = sub.node) === null || _sub$node4 === void 0 ? void 0 : (_sub$node4$status = _sub$node4.status) === null || _sub$node4$status === void 0 ? void 0 : _sub$node4$status.renewAt);
  }); // @ts-ignore

  const name = activeSub === null || activeSub === void 0 ? void 0 : (_activeSub$node2 = activeSub.node) === null || _activeSub$node2 === void 0 ? void 0 : (_activeSub$node2$item = _activeSub$node2.item) === null || _activeSub$node2$item === void 0 ? void 0 : _activeSub$node2$item.name;

  if (!name) {
    return PARTICLE_PLAN;
  }

  return name.includes(PARTICLE_PLAN) ? PARTICLE_PLAN : name.includes(ATOM_PLAN) ? ATOM_PLAN : CRYSTAL_PLAN;
};
export const cancelSubscriptionContract = async (subscriptionContractId, deactivate) => {
  if (!subscriptionContractId) throw new Error('Missing key information to cancel subscription');
  const query = `
  mutation CANCEL_SUBSCRIPTION_CONTRACT($id: String!, $deactivate: Boolean){
     subscriptions {
        cancel(id: $id, deactivate: $deactivate)
    }
  }`;

  try {
    const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query,
        variables: {
          id: subscriptionContractId,
          deactivate
        }
      })
    });
    const {
      data
    } = await response.json();
    if (data.subscriptions.cancel.errors) return new Error(ERROR_CANCELLING_SUBSCRIPTION);else return data.subscriptions.cancel;
  } catch (err) {
    throw new Error(`Sorry, there is a glitch. We couldn't cancel your existing subscription`);
  }
};